<template>
  <v-container class="py-10">
    <h1 class="font-bold text-xl mb-8">{{ $t('Login') }}</h1>
    <FormsLogin @success="success" />
    <div class="flex flex-col gap-2">
      <NuxtLink :to="localePath('/forgot-password')">
        {{ $t('Forgot your password?') }}
      </NuxtLink>
      <NuxtLink :to="localePath('/register')"> {{ $t("Don't have an account?") }} {{ $t('Register') }} </NuxtLink>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import FormsLogin from '~/components/Forms/Login.vue';

const localePath = useLocalePath();
const { t } = useI18n();
const router = useRouter();

function success() {
  router.push(localePath('/'));
}

useSeoMeta({ title: t('Login') });
</script>
